import { Link } from "react-router-dom";

import hand from './img/hand.png';
import fingerprint from './img/fingerprint.svg';
import close from './img/close.svg';

const Welcome = () => {
    return ( 
        <div className="welcome flex">
			<div className="welcome__box">
				<img src={hand} alt="" className="welcome__hand" />
				<h2 className="welcome__title">Вітаю, виберіть будь-ласка в якому форматі хочете переглянути резюме</h2>
				<div className="welcome__btns flex">
					<Link to="/home" className="btn blue flex">
						<img src={fingerprint} className="fingerpring" alt="" />
						<span>Веб-сайт резюме</span>
					</Link>
					<a href="https://cv.savytskyi-dev.site/OS_CV.pdf" className="btn red flex">
						<img src={close} className="close" alt="" />
						<span>Текстове резюме</span>
					</a>
				</div>
			</div>
		</div>
     );
}
 
export default Welcome;