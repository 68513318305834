import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";

import Fpage from "./Fpage";
import Banner from "./Banner";
import MainInfo from "./MainInfo";
import Contacts from "./Contacts";
import Capcha from "./Capcha";
import Video from "./Video";


function App() {

  const [isLoaded, changeIsLoaded] = useState(false);

  useEffect( () => {
    setTimeout( () => {
      changeIsLoaded(true);
    }, 2000)
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Fpage isLoaded={isLoaded} />} />
        <Route path="/home" element={<Banner />} />
        <Route path="/capcha" element={<Capcha />} />
        <Route path="/main" element={<MainInfo />} />
        <Route path="/contacts" element={<Contacts />} />
        {/* <Route path="/video" element={<Video />} /> */}
        {/* <Route path="*" element={<Eror404 />} /> */}
      </Routes>
    </div>
  );
}

export default App;
