import { Link } from "react-router-dom";

import fingerprint from './img/fingerprint.svg';
import man from './img/man.png';

const Banner = () => {
    return ( 
        <div className="banner">
			<div className="wrapper flex">
				<div className="banner__box">
					<h1 className="banner__title">Привіт, мене звати <span>Олег Савицький</span> і я Front-end Developer</h1>
					<p className="banner__descr">Якщо хочете дізнатися більше інформації про мене, натисніть на кнопку "Дізнатися більше". <br /> P.S. Сайт написаний на React</p>
					<Link to="/capcha" >
						<button className="btn banner__btn flex blue">
							<img src={fingerprint} className="fingerpring" alt="" />
							<span>Дізнатись більше</span>
						</button>
					</Link>
				</div>
				<img src={man} alt="" className="banner__man" />
			</div>
		</div>
     );
}
 
export default Banner;