import Welcome from "./Welcome";

const Fpage = ({isLoaded}) => {
    return ( 
		<>
			<div className={`fpage flex ${isLoaded ? 'active' : null}`}>
				<div className="fpage__wrap">
					<div className="fpage__box flex">
						<div className="fpage__o"></div>
						<div className="fpage__l active"></div>
						<div className="fpage__e active">
							<span></span>
							<span></span>
							<span></span>
						</div>
						<div className="fpage__h active">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					<div className="fpage__text">Savytskyi</div>
				</div>
			</div>
			<Welcome />
		</>
     );
}
 
export default Fpage;