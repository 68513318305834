import { Link } from "react-router-dom";

import fingerprint from './img/fingerprint.svg';

const Bio = () => {
    return ( 
        <div className="bio">
            <div className="bio__box flex">
                <div className="bio__image"></div>
                <div className="bio__info">
                    <p className="bio__text">Ім’я: Олег Савицький</p>
                    <p className="bio__text">Дата народження: 06.10.1997 (24 роки)</p>
                    <p className="bio__text">Місце проживання: м. Львів, вул. Зелена</p>

                    <p className="bio__title">Освіта</p>
                    <div className="bio__line"></div>

                    <p className="bio__text2">В 2018 році закінчив КНЕУ (Київський національний економічний університет ім. Вадима Гетьмана), факультет ФІСіТ, спеціальність комп’ютерні науки)</p>

                    <p className="bio__title">Додаткова інформація</p>
                    <div className="bio__line"></div>

                    <p className="bio__text">Рівень Англійської - Intermediate</p>
                    <p className="bio__text2">
                        Reading, listening - good. <br />
                        Writing, speaking - have some difficulties
                    </p>
                    <p className="bio__text mt20">Хобі</p>
                    <p className="bio__text2">Їзда на велосипеді/автомобілі. Люблю мандрувати.</p>
                </div>
                
            </div>
            <Link to="/contacts">
                <button className="btn bio__btn flex blue">
                    <img src={fingerprint} alt="" />
                    <span>Контактна інформація</span>
                </button>
            </Link>
        </div>
     );
}
 
export default Bio;