import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';

import fingerprint from './img/fingerprint.svg';
import git from './img/git.png';
import info from './img/info.png';
import html from './img/html.png';
import css from './img/css.png';
import js from './img/js.png';
import jquery from './img/jquery.png';
import react from './img/react.png';
import redux from './img/redux.png';
import sass from './img/sass.png';
import gulp from './img/gulp.png';
import ps from './img/ps.png';
import figma from './img/figma.png';

const Skills = () => {

    const [skills, updateSkills] = useState([
        {
            id: 1,
            status: false,
            images: [html, css],
            info: 'Активно використовував в роботі. Більше 3 років досвіду.',
        },
        {
            id: 2,
            status: false,
            images: [js, jquery],
            info: 'В більшості проектів використовував Jquery.',
        },
        {
            id: 3,
            status: false,
            images: [react, redux],
            info: 'Немає комерційного досвіду. Лише власті проекти.',
        },
        {
            id: 4,
            status: false,
            images: [sass, gulp],
            info: 'Активно використовую для верстки. Збираю проекти з Gulp.',
        },
        {
            id: 5,
            status: false,
            images: [ps, figma],
            info: 'Впевнений користувач. Можу задизайнити нескладний UI/UX',
        },
        {
            id: 6,
            status: false,
            images: [git],
            info: 'Немає досвіду використання. Лише теоретичні знання.',
        }
    ]);

    const changeStatus = () => {
        updateSkills(skills.map((skillsKey) => {
            skillsKey.status = true;
            return skillsKey;
        }));
    }

    useEffect( () => {
        setTimeout( () => {
            changeStatus();
        }, 1000)
    }, []);

    return ( 
        <div className="skills">
            <div className="skills__box">
                {
                    skills.map( (skillsKey) => (
                        <div className="skills__row flex" key={`skill-${skillsKey.id}`}>
                            <div className="skills__info">
                                <span className="skills__span">
                                    <img src={info} alt="" />
                                    
                                </span>
                                <span className="skills__popup">{skillsKey.info}</span>
                            </div>
                            <div className="skills__logos flex">
                                {
                                    skillsKey.images.map( (imageKey) => (
                                        <div className="skills__logo">
                                            <img src={imageKey} alt="" />
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="skills__res">
                                <div className={`skills__line skills__line_${skillsKey.id} ${skillsKey.status ? 'active' : null}`}>
                                    <div className="skills__round"></div>
                                    <span className="skills__val skills__min">0%</span>
                                    <span className="skills__val skills__max">100%</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="skills__btns flex">
                {/* <button className="btn blue flex" onClick={ () => changeStatus() }>
                    <img src={fingerprint} alt="" />
                    <span>Відкрити інформацію</span>
                </button> */}
                <Link to="/contacts">
                    <button className="btn flex blue">
                        <img src={fingerprint} alt="" />
                        <span>Контактна інформація</span>
                    </button>
                </Link>
            </div>
        </div>
     );
}
 
export default Skills;