import { Link } from "react-router-dom";
import React, {useEffect, useState } from 'react';
import parse from "html-react-parser";

import line from "./img/line.svg";
import fingerprint from './img/fingerprint.svg';

const Experience = () => {

	const [experience, updateExperience] = useState([
		{
			id: 1,
			date: '~2017',
			animation: false,
			text: 'Почав свій шлях в 2017. Після 2 місяців вивчення html, css, js  взяв в роботу проект, створення інтернет магазину на вордпресс за 2000 грн. Завдання успішно провалив))',
		},
		{
			id: 2,
			date: '2017- <br />2018',
			animation: false,
			text: 'Зрозумів, що працювати з вордпресс ще рано, тому зареєструвався на фрілансі. Успішно виконав декілька завдань на верстку сайтів.'
		},
		{
			id: 3,
			date: '2018- <br />2019',
			animation: false,
			text: 'Вирішив розширювати клієнтську базу, перейшов на інший фріланс, знайшов декілька постійних замовників. Успішно відпрацював більше року.'
		},
		{
			id: 4,
			date: '2019- <br />2022',
			animation: false,
			text: 'Познайомився з групою таргетологів, з якими успішно працював декілька років аж до цього дня. За цей час я встиг попрацювати з близько 100 різними замовниками (всі прийшли по рекомендації). Основні завдання: Верстка сайтів, створення квізів, копіювання сайтів, оптимізація вже існуючих сайтів, розробка сайтів на тільда, інтеграції з CRM, telegram, email і т.д.'
		},
		{
			id: 5,
			date: '2022...',
			animation: false,
			text: 'Прийняв рішення, що потрібно оновлювати технології розробки, тому зараз шукаю роботу в команді, щоб швидше рухатись до поставлених цілей. '
		},
	]);

	const addAnimation = (elId) => {
		updateExperience(
			experience.map( (experienceKey) => {
				if(experienceKey.id === elId){
					experienceKey.animation = true
				}
				return experienceKey;
			})
		);
		
	}

	useEffect( () => {
		let count = 0;

		let expItemInterval = setInterval( () => {
			if(count < 5){
				addAnimation(experience[count].id);
				count = count + 1;
			} else{
				clearInterval(expItemInterval);
			}
		}, 300);

	}, []);

    return ( 
        <div className="experience">
			<div className="experience__box">
				{
					experience.map( expKey => (
						<div className={`experience__item ${expKey.animation ? 'active' : null}`} key={expKey.id}>
							<div className="experience__round"></div>
							<img src={line} alt="" className="experience__line" />
							<span className="experience__date">{parse(expKey.date)}</span>
							<div className="experience__info">
								<p>{expKey.text}</p>
							</div>
						</div>
					))
				}
				
			</div>
			<center>
				<Link to="/contacts">
					<button className="btn flex blue">
						<img src={fingerprint} alt="" />
						<span>Контактна інформація</span>
					</button>
				</Link>
			</center>
		</div>
     );
}
 
export default Experience;