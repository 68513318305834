import React, { useEffect, useState } from 'react';

import Tabs from "./Tabs";

const MainInfo = () => {

    const [tabs, updateTabs] = useState([
        {
            status: true,
            name: 'Біографія',
            id: 1,
            blockStatus: true,
        },
        {
            status: false,
            name: 'Навики',
            id: 2,
            blockStatus: false,
        },
        {
            status: false,
            name: 'Досвід',
            id: 3,
            blockStatus: false,
        },
    ]);

    const changeStatus = (elId) => {
        updateTabs(tabs.map( tabsKey => {
            if(elId === tabsKey.id){
                tabsKey.status = true;
                tabsKey.blockStatus = true;
            } else{
                tabsKey.status = false;
                tabsKey.blockStatus = false;
            }
            return tabsKey;
        }));
    }

    return ( 
        <div className="minfo">
			<div className="wrapper">
                <Tabs tabs={tabs} changeStatus={changeStatus} />
                {/* <Bio />
                <Skills />
                <Experience /> */}
            </div>
        </div>
     );
}
 
export default MainInfo;