import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import change from "./img/change.svg";

const Capcha = () => {

	const [capcha, updateCapcha] = useState([
		{
			id: 111,
			question: 'Откуда на Белорусь готовилось нападение?',
			answer: 'с територии Украины',
			imageNumber: 1,
			status: true,
			result: false,
			order: 1,
			answers: [
				{
					id: 1111,
					name: 'с територии Польши',
				},
				{
					id: 1112,
					name: 'с територии Литвы',
				},
				{
					id: 1113,
					name: 'с територии России',
				},
				{
					id: 1114,
					name: 'с територии Украины',
				},
			]
		},
		{
			id: 211,
			question: 'Виконайте обчислення та виберіть правильну відповідь (2*5 = ?)',
			answer: 'результат: 10',
			imageNumber: 2,
			status: false,
			result: false,
			order: 2,
			answers: [
				{
					id: 2111,
					status: null,
					name: 'результат: 5',
				},
				{
					id: 2112,
					status: null,
					name: 'результат: 2',
				},
				{
					id: 2113,
					status: null,
					name: 'результат: 10',
				},
				{
					id: 2114,
					status: null,
					name: 'результат: 15',
				},
			]
		},
		{
			id: 311,
			question: 'Виконайте обчислення та виберіть правильну відповідь (11*11 = ?)',
			answer: 'результат: 121',
			imageNumber: 2,
			status: false,
			result: false,
			order: 3,
			answers: [
				{
					id: 3111,
					status: null,
					name: 'результат: 144',
				},
				{
					id: 3112,
					status: null,
					name: 'результат: 121',
				},
				{
					id: 3113,
					status: null,
					name: 'результат: 111',
				},
				{
					id: 3114,
					status: null,
					name: 'результат: 131',
				},
			]
		}
	]);


	const checkAnswer = (elId, answId, value, answer) => {
		updateCapcha(capcha.map( (capchaKey) => {
			if(capchaKey.id === elId){
				capchaKey.answers.map( (answKey) => {
					if(value === answer){
						capchaKey.result = true;

						if(answKey.id === answId){
							answKey.status = 'valid';
						} else{
							answKey.status = null;
						}
					} else{
						capchaKey.result = false;
						if(answKey.id === answId){
							answKey.status = 'error';
						} else{
							answKey.status = null;
						}
					}
					return answKey;
				});
			} else{
				capchaKey.result = false;
			}
			return capchaKey;
		} ))
	}

	const changeOrder = () => {
		const arrLength = capcha.length;
		updateCapcha(capcha.map( changeKey => {
			if(changeKey.order === arrLength){
				changeKey.order = 1;
			} else{
				changeKey.order += 1;
			}
			return changeKey;
		}));
		
		// function compare( a, b ) {
		// 	if ( a.order < b.order ){
		// 	  return -1;
		// 	}
		// 	if ( a.order > b.order ){
		// 	  return 1;
		// 	}
		// 	return 0;
		// }
		  
		// updateCapcha(capcha.sort(compare));
	}

    return ( 
        <div className="capcha">
			<div className="wrapper flex">
				<div className="capcha__box">
					<h2 className="capcha__title">Дайте відповідь на запитання, щоб переконатися, що ви не бот</h2>
						{
							capcha.map( (capchaKey) => (
								<div className={`capcha__ques ${(capchaKey.order === 1) ? 'flex' : null}`} key={capchaKey.id}>
									<div className={`capcha__image capcha__image_${capchaKey.imageNumber}`} ></div>
									<div className="capcha__info">
										<p className="capcha__questitle">{capchaKey.question}</p>
										<div className="capcha__answers flex">
											{
												capchaKey.answers.map( (answersKey) => (
													<div 
														className={`capcha__answer ${answersKey.status}`} 
														key={answersKey.id}
														onClick={ () => checkAnswer(capchaKey.id, answersKey.id, answersKey.name, capchaKey.answer) }>
														<input type="radio" 
															id={`answer-${answersKey.id}`} 
															name={`ques${capchaKey.id}`} 
															defaultValue={answersKey.name} />
														<label htmlFor={`answer-${answersKey.id}`}>{answersKey.name}</label>
													</div>
												))
											}
										</div>
										<div className="capcha__btns flex">
											<button className="btn flex yellow" onClick={() => changeOrder()}>
												<img src={change} className="change" alt="" />
												<span>Змінити запитання</span>
											</button>
											<Link to="/main" className={`btn flex blue capcha__btn ${capchaKey.result ? 'active' : null}`}>
												<span>Перейти далі</span>
											</Link>
										</div>
									</div>
								</div>
							))
						}
				</div>
			</div>
		</div>
     );
}
 
export default Capcha;