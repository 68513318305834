import man from './img/man2.png';
import phone from './img/phone.svg';
import mail from './img/mail.svg';
import telegram from './img/telegram.svg';
import place from './img/place.svg';

const Contacts = () => {
    return ( 
        <div className="contacts">
			<div className="wrapper flex">
				
				<div className="contacts__info">
					<h2 className="contacts__title">Контактна інформація</h2>
					<div className="contacts__link flex">
						<div className="contacts__icon">
							<img src={phone} alt="" />
						</div>
						<a href="tel:+380730519497">+38 (073) 051 94 97</a>
					</div>
					<div className="contacts__link flex">
						<div className="contacts__icon">
							<img src={mail} alt="" />
						</div>
						<a href="mailto:oleh123091973@gmail.com">oleh123091973@gmail.com</a>
					</div>
					<div className="contacts__link flex">
						<div className="contacts__icon">
							<img src={telegram} alt="" />
						</div>
						<a href="https://t.me/BeArN6">@BeArN_6</a>
					</div>
					<div className="contacts__link flex">
						<div className="contacts__icon">
							<img src={place} alt="" />
						</div>
						<p>Lviv, Ukraine</p>
					</div>
				</div>
				<img src={man} alt="" className="contacts__man" />
			</div>
		</div>
     );
}
 
export default Contacts;