import Skills from "./Skills";
import Bio from "./Bio";
import Experience from "./Experience";

const Tabs = ({tabs, changeStatus}) => {

    const currentBlock = (elId) => {
        if(elId === 1){
            return <Bio />;
        } else if(elId === 2){
            return <Skills />;
        } else{
            return <Experience />;
        }
    }

    return (
        <>
            <div className="tabs">
                <div className="tabs__box flex">
                    {tabs.map( tabKey => (
                        <a 
                            className={`tabs__item ${tabKey.status ? 'active' : null}`} 
                            key={`tab-${tabKey.id}`} 
                            onClick={() => changeStatus(tabKey.id)}>
                            <span>{tabKey.name}</span>
                        </a>
                    ))}
                </div>	
            </div>
            {tabs.map( tabKey => (
                tabKey.blockStatus ? currentBlock(tabKey.id) : null
            ))}
        </> 
     );
}
 
export default Tabs;